import * as Sentry from '@sentry/browser';
import debug from 'debug';
import LogRocket from 'logrocket';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { PrecacheEntry } from 'workbox-precaching/_types';

import { GetTagsResponseBodyI } from 'ApiClient';

import App from './App';
import { User } from './User';

const log = debug('bootstrap');
declare global {
  interface Window {
    __SESSION__: {
      user: User;
      requests: number;
    };
    __META__: {
      cdn: string;
      assetCdn: string;
      defaultPostsPerPage: number;
      posts: {
        count: number;
        averageRatio: number;
        frequencyByMonth: {
          [index: number]: number;
        };
        firstTimestamp: number;
        lastTimestamp: number;
      };
      tags: GetTagsResponseBodyI;
      disableSW: boolean;
      disableLR: boolean;
    };
    __PUBLIC_PATH__: string;
    __WB_MANIFEST: PrecacheEntry[];
    registration: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

log('ENV', process.env, window.__META__);
if (process.env.CI !== 'true' || !window.__META__.disableLR) {
  Sentry.init({
    release: process.env.VERSION,
    dsn:
      'https://56efb56b3ba44197ad36ba25fb1e64a6@o226655.ingest.sentry.io/1380053',
  });

  LogRocket.init('jhqmrq/carpe-dalan');
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });

  if (window?.__SESSION__?.user) {
    LogRocket.identify(window.__SESSION__.user, {
      requests: window.__SESSION__.requests,
    });
  }
}

if (!window.__META__.disableSW) {
  navigator?.serviceWorker?.register('/sw.js').then(
    () => {
      // registration.unregister();
      log('CLIENT: service worker registration complete.');
    },
    () => {
      log('CLIENT: service worker registration failure.');
    },
  );
}

/**
 * Dynamically set the public path so we can re-use assets for staging/local/prod.
 * This is needed for lazy chunks
 */
__webpack_public_path__ = window.__PUBLIC_PATH__; // eslint-disable-line

ReactDOM.render(
  <App {...window.__SESSION__} />,
  document.getElementById('root'),
);
