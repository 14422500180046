import debug from 'debug';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Picture from 'components/Picture';
import { PostsWithTagsWithFakes } from 'hooks/types';
import usePostLink from 'hooks/usePostLink';
import FlexContainer, { FlexEnums } from 'styles/FlexContainer';
import {
  BRAND_COLOR,
  formatDate,
  getThemeValue,
  TITLE_FONT,
  prop,
} from 'styles/utils';
import { getImageRatio, getOriginalImagePath } from 'utils';

const log = debug('components:Gallery');

const Description = styled.div`
  li {
    list-style: none;
    padding: 0;
    display: inline-block;
    margin-right: 0.25em;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  a {
    color: ${getThemeValue('brandColor')};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${getThemeValue('neutralColor')};
`;

const Download = styled.a`
  color: ${getThemeValue(BRAND_COLOR)};
  font-family: ${getThemeValue(TITLE_FONT)};
  text-transform: uppercase;
  text-decoration: none;
`;

const Header = styled(FlexContainer)<{ width?: string }>`
  background-color: rgba(255, 235, 250, 0.5);
  align-items: center;
  padding: 1em;
  width: ${prop('width')};
  align-self: center;
`;

interface PostI {
  post: PostsWithTagsWithFakes;
  isSquare?: boolean;
  width?: string;
  safeRef?: React.MutableRefObject<HTMLElement | null>;
  hasLink: boolean;
  posts: PostsWithTagsWithFakes[];
}

/**
 *
 *
 * @param {Omit<PostI, 'posts'>} {
 *   post,
 *   isSquare = false,
 *   width = '100%',
 *   safeRef,
 *   hasLink,
 * }
 * @returns JSX.Element
 */
const PostBody = ({
  post,
  width = '100%',
  safeRef,
  hasLink,
}: Omit<PostI, 'posts'>) => {
  const { Element, props } = usePostLink({ post, hasLink });
  return (
    <>
      <Header
        width={width}
        ref={safeRef}
        as="header"
        justifyContent={FlexEnums.spaceBetween}
      >
        <Download data-test="date" as="div">
          {post.timestamp ? formatDate(post.timestamp) : null}
        </Download>
        <Download data-test="download" href={getOriginalImagePath({ post })}>
          Download
        </Download>
      </Header>
      <Element {...props}>
        <Picture
          width={width}
          ratio={getImageRatio(post)}
          post={post}
          shouldShowImage
          placeholderColor={post.placeholder}
          alt={post.description}
          type="original"
        />
      </Element>
      {post.description || post.tags?.length ? (
        <Header
          width={width}
          as="header"
          justifyContent={FlexEnums.spaceBetween}
        >
          <Description>
            {post.description ? (
              <figcaption data-test="description">
                {post.description}
              </figcaption>
            ) : null}
            {post.tags?.length ? (
              <ul>
                {post.tags.map(({ name }) => (
                  <li data-test="tags" key={name}>
                    <StyledLink to={`/tag/${name}`}>{`#${name}`}</StyledLink>
                  </li>
                ))}
              </ul>
            ) : null}
          </Description>
        </Header>
      ) : null}
    </>
  );
};

export default PostBody;
