import debug from 'debug';
import * as React from 'react';
import { RouteProps } from 'react-router';
import { Route, Routes, Link } from 'react-router-dom';
import styled from 'styled-components';

import withErrorBoundary from 'components/ErrorBoundary';
import SidebarAndMenu from 'components/SidebarAndMenu';
import Toaster from 'components/Toaster';
import useUser from 'hooks/useUser';
import Login from 'pages/Login';
import Request from 'pages/Request';
import Slash from 'pages/Slash';
import Tag from 'pages/Tag';
import Button from 'styles/Button';
import Title from 'styles/Title';

const log = debug('components:Routes');

const LoggedInRoute = (props: RouteProps) => {
  const { user: globalUser } = useUser();
  if (!globalUser) return null;
  return <Route {...props} />;
};
const { Suspense } = React;

const Spinner = () => <div>12341234i1234124oij</div>;

const AppWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Cenetered = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  & a {
    text-decoration: none;
    color: white;
  }
`;

const FourOhFour = () => (
  <Cenetered>
    <Title>This page doesn&apos;t exist.</Title>
    <Button type="button">
      <Link to="/">Back to safety</Link>
    </Button>
  </Cenetered>
);

const SkipNav = styled.a`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;

  &:focus {
    padding: 1rem;
    position: fixed;
    top: 10px;
    left: 10px;
    background: white;
    z-index: 3;
    width: auto;
    height: auto;
    clip: auto;
  }
`;

const AppRoutes: React.FC = () => {
  const { user: globalUser } = useUser();
  return (
    <AppWrapper>
      <SkipNav href="#maincontent">Skip to main content </SkipNav>
      <Suspense fallback={<Spinner />}>
        <Toaster />
        <SidebarAndMenu />
        <Routes>
          <Route path="/request" element={<Request />} />
          <LoggedInRoute path="/tag/:tagName/*" element={<Tag />} />
          <LoggedInRoute
            path="/*"
            element={globalUser ? <Slash /> : <Login />}
          />
          <Route path="/login" element={<Login />} />

          <Route path="*" element={<FourOhFour />} />
        </Routes>
      </Suspense>
    </AppWrapper>
  );
};

export default withErrorBoundary({ Component: AppRoutes, namespace: 'Routes' });
