import React from 'react';

export default () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24px"
      height="22px"
      viewBox="0 0 380 380"
    >
      <g>
        <g>
          <g>
            <path
              d="M370,35.025H10c-5.522,0-10,4.477-10,10v289.949c0,5.522,4.478,10,10,10h360c5.522,0,10-4.478,10-10V45.025
				C380,39.502,375.522,35.025,370,35.025z M342.285,307.26H37.715V72.74h304.57V307.26z"
            />
            <path
              d="M75.555,283.303h228.891c5.121,0,9.271-4.151,9.271-9.272v-53.155c0-2.42-0.943-4.743-2.637-6.476l-25.474-26.114
				c-3.475-3.559-9.133-3.745-12.83-0.425l-48.028,43.106l-82.928-75.259c-3.35-3.038-8.398-3.219-11.955-0.428
				c-11.679,9.165-37.859,29.708-60.033,47.094c-2.241,1.758-3.551,4.448-3.551,7.297v64.358
				C66.282,279.15,70.434,283.303,75.555,283.303z"
            />
            <path
              d="M227.602,197.822c11.483,0,20.826-9.343,20.826-20.825c0-11.483-9.343-20.825-20.826-20.825
				c-11.48,0-20.823,9.342-20.823,20.825C206.777,188.479,216.119,197.822,227.602,197.822z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
