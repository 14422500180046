import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { client as api } from 'ApiClient';
import useApi from 'hooks/useApi';
import useForm from 'hooks/useForm';
import useUser from 'hooks/useUser';
import Button from 'styles/Button';
import Input from 'styles/Input';

import { InputForm, InputWrapper, StyledTitle } from './styles';

const { useEffect } = React;

/**
 * Login component. Return markup for page level component
 *
 * @returns {React.ReactElement}
 */
const Login = (): React.ReactElement => {
  const { setUser } = useUser();
  const navigate = useNavigate();
  const { useField } = useForm('myForm');

  const passwordInprut = useField({ field: 'password' });
  const { request, response } = useApi(api.login);

  useEffect(() => {
    if (response?.user) {
      setUser(response.user);
      navigate('/');
      if (process.env.CI !== 'true' || !window.__META__.disableLR) {
        const LogRocket = require('logrocket'); // eslint-disable-line @typescript-eslint/no-var-requires,global-require
        LogRocket.identify(response.user, {});
      }
    }
  }, [navigate, response, setUser]);

  /**
   * Submit hanlder. Calls the login APIz.
   *
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    request({
      requestBody: {
        password: String(passwordInprut.value),
      },
    });
  };
  const passwordId = 'passwordzez';
  return (
    <InputWrapper>
      <InputForm
        data-test="submit"
        data-testid="submit"
        onSubmit={handleSubmit}
      >
        <StyledTitle as="label" htmlFor={passwordId} center>
          Login
        </StyledTitle>
        <Input>
          <input
            id={passwordId}
            data-test="password"
            type="password"
            {...passwordInprut}
          />
        </Input>
        <Button
          data-test="submit-button"
          data-testid="submit-button"
          type="submit"
        >
          Submit
        </Button>
        <a href="mailto:carpedalan@gmail.com?subject=Access Request&body=Please supply me with the password for carpedalan.com. Thanks!">
          Request access
        </a>
      </InputForm>
    </InputWrapper>
  );
};

export default Login;
