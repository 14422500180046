import React from 'react';

export default () => {
  return (
    <svg
      width="25px"
      height="22px"
      viewBox="0 0 25 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>grid [#1524]</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Dribbble-Light-Preview"
          transform="translate(-299.000000, -200.000000)"
          fill="#000000"
        >
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path
              d="M254.55,49 L261.9,49 L261.9,42 L254.55,42 L254.55,49 Z M254.55,58 L261.9,58 L261.9,51 L254.55,51 L254.55,58 Z M245.1,49 L252.45,49 L252.45,42 L245.1,42 L245.1,49 Z M245.1,58 L252.45,58 L252.45,51 L245.1,51 L245.1,58 Z M243,60 L264,60 L264,40 L243,40 L243,60 Z"
              id="grid-[#1524]"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
