import debug from 'debug';
import React, { useRef, lazy } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import GalleryPost from 'components/GalleryPost';
import Modal from 'components/Modal';
import { PostsWithTagsWithFakes } from 'hooks/types';
import useUser from 'hooks/useUser';
import useWindow from 'hooks/useWindow';
import FlexContainer, { FlexEnums } from 'styles/FlexContainer';
import { getImageRatio } from 'utils';

import PostBody from '../GalleryPost/PostBody';

const AdminPostBody = lazy(() => import('../GalleryPost/AdminPostBody'));

const log = debug('components:Gallery');

const { center } = FlexEnums;

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;

/**
 * Gallery component. Needs to take in the post list as gallery post needs
 * previous and next in the "list"
 *
 * @param {{ posts: PostsWithTagsWithFakes[] }} { posts }
 * @returns
 */
const Gallery = ({ posts }: { posts: PostsWithTagsWithFakes[] }) => {
  const { width, height } = useWindow();
  const safeRef = useRef(null);
  const params = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useUser();

  /**
   * Router uses the first segment of a uuid. Find it here
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const post = posts.find(({ id }) => params.postId === id?.split('-')[0]);
  const onClose = () => {
    /**
     * params['*'] is effectively "all matches"
     */
    const routeWithoutGallery =
      location.pathname.replace(`/${params['*']}`, '') || '/';
    log('route', routeWithoutGallery);
    navigate(`${routeWithoutGallery}${location.hash}`);
  };

  /**
   * Short circuit if the post doesnt exist.
   */
  if (!post) {
    onClose();
    return null;
  }
  const viewPortAspectRatio = height / width;

  const photoAspectRatio = getImageRatio(post);

  const photoWidth =
    photoAspectRatio > viewPortAspectRatio
      ? `${height / photoAspectRatio - 100}px`
      : '100%';

  const PostBodyComponent = isAdmin ? AdminPostBody : PostBody;

  return (
    <Modal onClose={onClose} safeRef={safeRef}>
      <Container alignItems={center} justifyContent={center}>
        <GalleryPost
          hasLink={false}
          safeRef={safeRef}
          post={post}
          width={photoWidth}
          posts={posts}
          PostBody={PostBodyComponent}
        />
      </Container>
    </Modal>
  );
};

export default Gallery;
