import * as React from 'react';

import { PostsWithTagsWithFakes } from 'hooks/types';

import {
  HIRES,
  MEDIUM,
  SIZE_MAP,
  Sizes,
  SMALL,
  SMALL_THUMB,
  TINY,
} from './constants';

const { cdn } = window.__META__;

interface GetImagePath {
  post: PostsWithTagsWithFakes;
  size: Sizes;
  type: 'jpg' | 'webp';
  prefix?: 'web' | 'original';
}

export const getImagePath = ({
  post,
  size,
  type = 'jpg',
  prefix = 'web',
}: GetImagePath): string => {
  const { width, height } = SIZE_MAP[size];
  const base = post.key?.split('/')[1]?.split('.')[0];
  const extension = `${width}${height ? `-${height}` : ''}`;
  if (!post.fake && base)
    return `//${cdn}/${prefix}/${base}-${extension}.${type}`;
  return '';
};

export const getOriginalImagePath = ({
  post,
}: {
  post: PostsWithTagsWithFakes;
}) => (post.fake ? '' : `//${cdn}/${post.key}`);

export const getFullImageSrcSet = ({
  post,
  shouldBeLazy,
}: {
  post: PostsWithTagsWithFakes;
  shouldBeLazy: boolean;
}): React.ReactElement => (
  <>
    <source
      srcSet={`${getImagePath({ post, size: MEDIUM, type: 'webp' })}, 
  ${getImagePath({ post, size: HIRES, type: 'webp' })} 1.5x,
  ${getImagePath({ post, size: HIRES, type: 'webp' })} 2x`}
      type="image/webp"
      media="(min-width: 768px)"
    />
    <source
      srcSet={`${getImagePath({ post, size: MEDIUM, type: 'jpg' })}, 
  ${getImagePath({ post, size: HIRES, type: 'jpg' })} 1.5x,
  ${getImagePath({ post, size: HIRES, type: 'jpg' })} 2x`}
      type="image/jpg"
      media="(min-width: 768px)"
    />
    <source
      srcSet={`${getImagePath({ post, size: SMALL, type: 'webp' })}, 
  ${getImagePath({ post, size: MEDIUM, type: 'webp' })} 1.5x,
  ${getImagePath({ post, size: MEDIUM, type: 'webp' })} 2x`}
      type="image/webp"
      media="(min-width: 500px)"
    />
    <source
      srcSet={`${getImagePath({ post, size: MEDIUM, type: 'jpg' })}, 
  ${getImagePath({ post, size: MEDIUM, type: 'jpg' })} 1.5x,
  ${getImagePath({ post, size: MEDIUM, type: 'jpg' })} 2x`}
      type="image/jpg"
      media="(min-width: 500px)"
    />
    <source
      srcSet={`${getImagePath({ post, size: TINY, type: 'webp' })},
  ${getImagePath({ post, size: MEDIUM, type: 'webp' })} 1.5x,
  ${getImagePath({ post, size: MEDIUM, type: 'webp' })} 2x`}
      type="image/webp"
      media="(max-width: 499px)"
    />
    <source
      srcSet={`${getImagePath({ post, size: TINY, type: 'jpg' })},
  ${getImagePath({ post, size: MEDIUM, type: 'jpg' })} 1.5x,
  ${getImagePath({ post, size: MEDIUM, type: 'jpg' })} 2x`}
      type="image/jpg"
      media="(max-width: 499px)"
    />
    <img
      src={getImagePath({ post, size: MEDIUM, type: 'jpg' })}
      alt={post.description || post.key}
      {...(shouldBeLazy ? { loading: 'lazy' } : {})}
    />
  </>
);

export const getSquareImageSrcSet = ({
  post,
  shouldBeLazy,
}: {
  post: PostsWithTagsWithFakes;
  shouldBeLazy: boolean;
}) => (
  <>
    <source
      srcSet={getImagePath({ post, size: SMALL_THUMB, type: 'webp' })}
      type="image/webp"
    />
    <source
      srcSet={getImagePath({ post, size: SMALL_THUMB, type: 'jpg' })}
      type="image/jpg"
    />
    <img
      src={getImagePath({ post, size: SMALL_THUMB, type: 'jpg' })}
      alt={post.description || post.key}
      {...(shouldBeLazy ? { loading: 'lazy' } : {})}
    />
  </>
);

export const getImageRatio = (post: PostsWithTagsWithFakes) => {
  if (post.fake) return 1;
  if (!post.imageHeight || !post.imageWidth) return 1;
  let ratio = Number(post.imageHeight) / Number(post.imageWidth);
  if (Number(post.orientation) === 6 || Number(post.orientation) === 8)
    ratio = 1 / ratio;
  return ratio;
};
