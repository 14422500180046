import * as Sentry from '@sentry/browser';
import React, { ComponentType } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'styles/Button';
import FlexContainer, { FlexEnums } from 'styles/FlexContainer';
import Title from 'styles/Title';

const Wrapper = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-align: center;
`;

/**
 * Error boundry HOC. In the event of an error, this will display an oopsie
 * message and log some console errors. In production it will forward the errors
 * to Sentry.
 *
 * @export
 * @template P
 * @param {{
 *   Component: ComponentType<P>;
 *   namespace?: string;
 * }} {
 *   Component,
 *   namespace = 'no-namespace',
 * }
 * @returns React.Component
 */
export default function withErrorBoundary<P extends Record<string, unknown>>({
  Component,
  namespace = 'no-namespace',
}: {
  Component: ComponentType<P>;
  namespace?: string;
}) {
  return class extends React.Component<P, { hasError: boolean }> {
    constructor(props: P) {
      super(props);
      this.state = { hasError: false };
    }

    private static getDerivedStateFromError() {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    /**
     * React lifecycle method that will swallow errors.
     *
     * @param {(Error | null)} error
     * @param {object} info
     */
    componentDidCatch(error: Error | null, info: any) {
      /* eslint-disable no-console */
      if (!process.env.CI) {
        Sentry.captureMessage(
          `Error in boundary ${namespace}`,
          Sentry.Severity.Critical,
        );
        Sentry.captureException(error);
      }
      console.error(`Caught Error for ${namespace}!`);
      console.info(info);
      console.error(error);
      /* eslint-enable no-console */
    }

    render() {
      const {
        props,
        state: { hasError },
      } = this;
      if (hasError) {
        return (
          <Wrapper
            alignItems={FlexEnums.center}
            justifyContent={FlexEnums.center}
          >
            <Title>Oops!</Title>
            <Title>Something went wrong.</Title>
            <Button type="button">
              <Link to="/">Back to safety</Link>
            </Button>
          </Wrapper>
        );
      }

      return <Component {...props} />;
    }
  };
}
